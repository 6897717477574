<template>
  <div class="page">
    <Breadcrumbs/>
    <div class="container">
      <div class="column spacer">
        <h1>Veel gestelde vragen</h1>
        <h2>Veelgestelde vragen over hosting</h2>
        <p>We zetten de antwoorden op de veelgestelde vragen over hosting voor u op een rij. Antwoorden op hosting vragen door onze klanten:</p>
        <ul>
          <li>Wat heb ik nodig om een website te starten?</li>
          <li>Welke webhosting heb ik nodig?</li>
          <li>Hoe kan ik een domeinnaam registreren?</li>
          <li>Hoe kan ik mijn domeinnaam verhuizen?</li>
          <li>Wat is een SSL certificaat?</li>
        </ul>
        <h3>Wat heb ik nodig om een website te starten?</h3>
        <p>Een van de veelgestelde vragen over hosting, want wat heeft u nodig om een eigen website te starten? De basis is gelukkig erg eenvoudig, u heeft voldoende aan een eigen domeinnaam en een pakket voor webhosting. De domeinnaam is het online adres dat bezoekers kunnen intypen om uw website te bezoeken, met de webhosting zorgt u ervoor dat de websitebestanden op een server staan die 24/7 is verbonden met het internet en daarmee altijd bereikbaar is.</p>
        <h3>Welke webhosting heb ik nodig?</h3>
        <p>Het <router-link to="/webhosting/">ideale pakket voor webhosting</router-link> is afhankelijk van een paar belangrijke factoren:</p>
        <ul>
          <li>De beschikbare webruimte (in MB’s)</li>
          <li>Het toegestane dataverkeer per maand (in GB’s)</li>
          <li>Het aantal beschikbare e-mailadressen</li>
        </ul>
        <p>Kies een pakket dat past bij de manier waarop u daar gebruik van wilt maken of laat u daarover adviseren door onze specialisten. Eventueel kunt u rekening houden met het aantal MySQL-databases dat u kunt gebruiken (bijvoorbeeld voor een CMS, zoals WordPress) en het aantal subdomeinen dat er beschikbaar is. We kunnen bovendien zorgen voor uitgebreide webstatistieken, een virussscanner en spamfilter.</p>
        <h3>Hoe kan ik een domeinnaam registreren?</h3>
        <p>Het is een van de veelgestelde vragen over hosting, waar gelukkig een eenvoudig antwoord op mogelijk is. Een domeinnaam registreren kan gewoon online door de domeinnaam in te voeren die graag zou gebruiken. We controleren in realtime bij SIDN of de naam nog beschikbaar is en of we die voor u kunnen registreren.</p>
        <p><i>Tip: is de domeinnaam al bezet? Probeer een andere extensie (.nl, .eu, .net, etc.) of gebruik een koppelteken (‘-‘) om een alternatieve domeinnaam te kunnen registreren. </i></p>
        <h3>Hoe kan ik mijn domeinnaam verhuizen?</h3>
        <p>In het verlengde van de vorige veelgestelde vraag vragen veel klanten ons hoe ze hun domeinnaam kunnen verhuizen. Dat leggen we online graag uit, zodat het verhuizen soepel verloopt en u een <router-link to="/domeinnamen/domeinnaam-verhuizen/">domeinnaam naar ons kunt verhuizen</router-link> of juist elders kunt gebruiken.</p>
        <h3>Wat is een SSL certificaat?</h3>
        <p>Het SSL-certficaat (Secure Socket Layer) zorgt voor een veilige verbinding, op basis van het HTTPS-protocol. Dit certificaat is verplicht voor alle websites die gegevens van bezoekers verzamelen en verzenden. Dat geldt voor webshops, net als voor alle websites met een contactformulier. We kunnen bij Hosting on Demand <router-link to="/ssl-certificaten/">verschillende SSL-certificaten</router-link> verzorgen en daarmee een veilige verbinding garanderen.</p>
        <h2>Veel gestelde vragen over Dedicated servers</h2>
        <div
          v-for="(question, index) in questionsDedicatedServers"
          :style="index < questionsDedicatedServers.length - 1 ? 'margin-bottom: 20px;' : ''"
          :key="index"
        >
          <ExpansionPanels
            :panels="[question.title]"
          >
            <template v-slot:content-text-0>
              <div v-html="question.content"></div>
            </template>
          </ExpansionPanels>
        </div>
        <h2>Veel gestelde vragen over Domeinnamen</h2>
        <div
          v-for="(question, index) in questionsDomainName"
          :style="index < questionsDomainName.length - 1 ? 'margin-bottom: 20px;' : ''"
          :key="index"
        >
          <ExpansionPanels
            :panels="[question.title]"
          >
            <template v-slot:content-text-0>
              <div v-html="question.content"></div>
            </template>
          </ExpansionPanels>
        </div>
        <h2>Veel gestelde vragen over Eigen website maken</h2>
        <div
          v-for="(question, index) in questionsCreatingOwnWebsite"
          :style="index < questionsCreatingOwnWebsite.length - 1 ? 'margin-bottom: 20px;' : ''"
          :key="index"
        >
          <ExpansionPanels
            :panels="[question.title]"
          >
            <template v-slot:content-text-0>
              <div v-html="question.content"></div>
            </template>
          </ExpansionPanels>
        </div>
        <h2>Veel gestelde vragen over GDPR / AVG</h2>
        <div
          v-for="(question, index) in questionsGDPRAVG"
          :style="index < questionsGDPRAVG.length - 1 ? 'margin-bottom: 20px;' : ''"
          :key="index"
        >
          <ExpansionPanels
            :panels="[question.title]"
          >
            <template v-slot:content-text-0>
              <div v-html="question.content"></div>
            </template>
          </ExpansionPanels>
        </div>
        <h2>Veel gestelde vragen over Hosting</h2>
        <div
          v-for="(question, index) in questionsWebhosting"
          :style="index < questionsWebhosting.length - 1 ? 'margin-bottom: 20px;' : ''"
          :key="index"
        >
          <ExpansionPanels
            :panels="[question.title]"
          >
            <template v-slot:content-text-0>
              <div v-html="question.content"></div>
            </template>
          </ExpansionPanels>
        </div>
        <h2>Veel gestelde vragen over SSL</h2>
        <div
          v-for="(question, index) in questionsSSLCertificates"
          :style="index < questionsSSLCertificates.length - 1 ? 'margin-bottom: 20px;' : ''"
          :key="index"
        >
          <ExpansionPanels
            :panels="[question.title]"
          >
            <template v-slot:content-text-0>
              <div v-html="question.content"></div>
            </template>
          </ExpansionPanels>
        </div>
        <h2>Veel gestelde vragen over VPS</h2>
        <div
          v-for="(question, index) in questionsCloudServers"
          :style="index < questionsCloudServers.length - 1 ? 'margin-bottom: 20px;' : ''"
          :key="index"
        >
          <ExpansionPanels
            :panels="[question.title]"
          >
            <template v-slot:content-text-0>
              <div v-html="question.content"></div>
            </template>
          </ExpansionPanels>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';

import ExpansionPanels from '@/components/ExpansionPanels.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';

export default {
  name: 'FrequentlyAskedQuestions',
  components: {
    ExpansionPanels,
    Breadcrumbs,
  },
  setup() {
    const data = inject('data');
    const questionsDedicatedServers = data.staticData.questions.filter(q => q.tags.split(' ').includes('dedicated-servers'));
    const questionsDomainName = data.staticData.questions.filter(q => q.tags.split(' ').includes('domain-name'));
    const questionsCreatingOwnWebsite = data.staticData.questions.filter(q => q.tags.split(' ').includes('creating-own-website'));
    const questionsGDPRAVG = data.staticData.questions.filter(q => q.tags.split(' ').includes('gdpr-avg'));
    const questionsWebhosting = data.staticData.questions.filter(q => q.tags.split(' ').includes('webhosting'));
    const questionsSSLCertificates = data.staticData.questions.filter(q => q.tags.split(' ').includes('ssl-certificates'));
    const questionsCloudServers = data.staticData.questions.filter(q => q.tags.split(' ').includes('cloud-servers'));

    return {
      // static data
      questionsDedicatedServers,
      questionsDomainName,
      questionsCreatingOwnWebsite,
      questionsGDPRAVG,
      questionsWebhosting,
      questionsSSLCertificates,
      questionsCloudServers,
    };
  },
}
</script>